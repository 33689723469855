/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Connection } from '../models/Connection';
import type { Contacts } from '../models/Contacts';
import type { CreateSlot } from '../models/CreateSlot';
import type { EventDestroyRequest } from '../models/EventDestroyRequest';
import type { EventRequestAccept } from '../models/EventRequestAccept';
import type { GenerateSlotsRequest } from '../models/GenerateSlotsRequest';
import type { GenerateSlotsResponse } from '../models/GenerateSlotsResponse';
import type { PatchedCreateSlot } from '../models/PatchedCreateSlot';
import type { PatchedUser } from '../models/PatchedUser';
import type { PatchedUserProfile } from '../models/PatchedUserProfile';
import type { PatchedWriteOnlyEventRequest } from '../models/PatchedWriteOnlyEventRequest';
import type { ReadOnlyEventRequest } from '../models/ReadOnlyEventRequest';
import type { ReadOnlyUpcomingEvent } from '../models/ReadOnlyUpcomingEvent';
import type { Slot } from '../models/Slot';
import type { SlotsAndEvents } from '../models/SlotsAndEvents';
import type { User } from '../models/User';
import type { UserProfile } from '../models/UserProfile';
import type { WriteOnlyEventRequest } from '../models/WriteOnlyEventRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ApiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public apiAuthLogoutRetrieve(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/auth/logout/',
        });
    }

    /**
     * @returns SlotsAndEvents
     * @throws ApiError
     */
    public apiCalendarList({
        end,
        start,
    }: {
        /**
         * End date
         */
        end: string,
        /**
         * Start date
         */
        start: string,
    }): CancelablePromise<Array<SlotsAndEvents>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/calendar/',
            query: {
                'end': end,
                'start': start,
            },
        });
    }

    /**
     * Get all contacts or search contacts from the user's account (Google or Microsoft)
     * @returns Contacts
     * @throws ApiError
     */
    public apiContactsList({
        query,
    }: {
        /**
         * Search query for filtering contacts. If not provided, returns all contacts.
         */
        query?: string,
    }): CancelablePromise<Array<Contacts>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contacts/',
            query: {
                'query': query,
            },
        });
    }

    /**
     * @returns ReadOnlyUpcomingEvent
     * @throws ApiError
     */
    public apiInvitedEventsList(): CancelablePromise<Array<ReadOnlyUpcomingEvent>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/invited-events/',
        });
    }

    /**
     * @returns ReadOnlyUpcomingEvent
     * @throws ApiError
     */
    public apiPendingEventsList(): CancelablePromise<Array<ReadOnlyUpcomingEvent>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/pending-events/',
        });
    }

    /**
     * @returns ReadOnlyUpcomingEvent
     * @throws ApiError
     */
    public apiPendingEventsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this event request.
         */
        id: number,
    }): CancelablePromise<ReadOnlyUpcomingEvent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/pending-events/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ReadOnlyEventRequest
     * @throws ApiError
     */
    public apiRequestsList(): CancelablePromise<Array<ReadOnlyEventRequest>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/requests/',
        });
    }

    /**
     * Creates an event request
     * @returns ReadOnlyEventRequest
     * @throws ApiError
     */
    public apiRequestsCreate({
        requestBody,
    }: {
        requestBody: WriteOnlyEventRequest,
    }): CancelablePromise<ReadOnlyEventRequest> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/requests/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public apiRequestsSendReminderCreate({
        eventRequestId,
    }: {
        eventRequestId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/requests/{event_request_id}/send-reminder/',
            path: {
                'event_request_id': eventRequestId,
            },
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public apiRequestsSlotsSetFinalSlotCreate({
        eventRequestId,
        slotId,
    }: {
        eventRequestId: string,
        slotId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/requests/{event_request_id}/slots/{slot_id}/set-final-slot/',
            path: {
                'event_request_id': eventRequestId,
                'slot_id': slotId,
            },
        });
    }

    /**
     * @returns ReadOnlyEventRequest
     * @throws ApiError
     */
    public apiRequestsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this event request.
         */
        id: number,
    }): CancelablePromise<ReadOnlyEventRequest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/requests/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Updates an event request
     * @returns ReadOnlyEventRequest
     * @throws ApiError
     */
    public apiRequestsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this event request.
         */
        id: number,
        requestBody: WriteOnlyEventRequest,
    }): CancelablePromise<ReadOnlyEventRequest> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/requests/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Partial updates an event request
     * @returns ReadOnlyEventRequest
     * @throws ApiError
     */
    public apiRequestsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this event request.
         */
        id: number,
        requestBody?: PatchedWriteOnlyEventRequest,
    }): CancelablePromise<ReadOnlyEventRequest> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/requests/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns EventRequestAccept
     * @throws ApiError
     */
    public apiRequestsAcceptCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this event request.
         */
        id: number,
        requestBody?: EventRequestAccept,
    }): CancelablePromise<EventRequestAccept> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/requests/{id}/accept/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Cancel an event request
     * @returns void
     * @throws ApiError
     */
    public apiRequestsCancelCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this event request.
         */
        id: number,
        requestBody?: EventDestroyRequest,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/requests/{id}/cancel/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GenerateSlotsResponse
     * @throws ApiError
     */
    public apiRequestsGenerateSlotsCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this event request.
         */
        id: number,
        requestBody: GenerateSlotsRequest,
    }): CancelablePromise<GenerateSlotsResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/requests/{id}/generate-slots/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ReadOnlyEventRequest
     * @throws ApiError
     */
    public apiRequestsInvitationList(): CancelablePromise<Array<ReadOnlyEventRequest>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/requests/invitation/',
        });
    }

    /**
     * @returns SlotsAndEvents
     * @throws ApiError
     */
    public apiSlotsList({
        end,
        eventRequest,
        start,
    }: {
        end?: string,
        eventRequest?: number,
        start?: string,
    }): CancelablePromise<Array<SlotsAndEvents>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/slots/',
            query: {
                'end': end,
                'event_request': eventRequest,
                'start': start,
            },
        });
    }

    /**
     * @returns Slot
     * @throws ApiError
     */
    public apiSlotsCreate({
        requestBody,
    }: {
        requestBody: CreateSlot,
    }): CancelablePromise<Slot> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/slots/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Slot
     * @throws ApiError
     */
    public apiSlotsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this slot.
         */
        id: number,
    }): CancelablePromise<Slot> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/slots/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Slot
     * @throws ApiError
     */
    public apiSlotsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this slot.
         */
        id: number,
        requestBody: CreateSlot,
    }): CancelablePromise<Slot> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/slots/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Slot
     * @throws ApiError
     */
    public apiSlotsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this slot.
         */
        id: number,
        requestBody?: PatchedCreateSlot,
    }): CancelablePromise<Slot> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/slots/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public apiSlotsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this slot.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/slots/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Slot
     * @throws ApiError
     */
    public apiSlotsSelectCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this slot.
         */
        id: number,
        requestBody: any,
    }): CancelablePromise<Slot> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/slots/{id}/select/',
            path: {
                'id': id,
            },
            body: requestBody,
        });
    }

    /**
     * @returns Slot
     * @throws ApiError
     */
    public apiSlotsUnselectCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this slot.
         */
        id: number,
        requestBody: any,
    }): CancelablePromise<Slot> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/slots/{id}/unselect/',
            path: {
                'id': id,
            },
            body: requestBody,
        });
    }

    /**
     * @returns User
     * @throws ApiError
     */
    public apiUsersMeRetrieve(): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/me/',
        });
    }

    /**
     * @returns User
     * @throws ApiError
     */
    public apiUsersMeUpdate({
        requestBody,
    }: {
        requestBody?: User,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/users/me/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns User
     * @throws ApiError
     */
    public apiUsersMePartialUpdate({
        requestBody,
    }: {
        requestBody?: PatchedUser,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/users/me/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Connection
     * @throws ApiError
     */
    public apiUsersMeConnectionsRetrieve(): CancelablePromise<Connection> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/me/connections/',
        });
    }

    /**
     * @returns UserProfile
     * @throws ApiError
     */
    public apiUsersMeProfileRetrieve(): CancelablePromise<UserProfile> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/me/profile/',
        });
    }

    /**
     * @returns UserProfile
     * @throws ApiError
     */
    public apiUsersMeProfileUpdate({
        requestBody,
    }: {
        requestBody: UserProfile,
    }): CancelablePromise<UserProfile> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/users/me/profile/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserProfile
     * @throws ApiError
     */
    public apiUsersMeProfilePartialUpdate({
        requestBody,
    }: {
        requestBody?: PatchedUserProfile,
    }): CancelablePromise<UserProfile> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/users/me/profile/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
